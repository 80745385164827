import React from "react";
import helpProject from "./assets/hilfe_projekt.png";

const ProjectManual = () => {
  return (
    <div className="log-event__container">
      <div className="log-event__container-inner">
        <h2>Projekt</h2>

        <p>
          Hier sehen Sie alle Kameras des ausgewählten Projekts. Rechts oberhalb
          des Bilds ist Datum und Uhrzeit des zuletzt übertragenen Bilds.
        </p>
        <img src={helpProject} alt="help_project" />

        <h3>Navigation</h3>
        <p>
          Ein Klick aufs Bild bringt sie zum Player. Hier können Sie durch alle
          Bilder des Archivs blättern. Die Buttons ‚Archiv‘, je nach Buchung
          auch ‚Live‘ und ‚Zeitraffer‘, bringen Sie zu den jeweiligen Seiten.
        </p>

        <h3>Drei parallele Striche</h3>
        <p>
          Durch Klick auf diesen Button werden die Bilder alle Kameras so groß
          wie möglich dargestellt, Sie erhalten so einen guten Überblick über
          die aktuelle Situation. Alle Bilder werden in der Cloud aus
          technischen Gründen nur in einer verkleinerten Vorschaugröße
          angezeigt, die Original-Bilder werden unverändert in Originalgröße
          gespeichert. Bilder in Originalgröße erhalten Sie über den Download.
        </p>

        <h3>Grüner Punkt</h3>
        <p>
          Ein grüner Punkt links oberhalb des Bilds zeigt, dass die Kamera aktiv
          ist und Bilder in die BAU.CAMERA Cloud übertragen werden.
        </p>

        <h3>Roter Punkt</h3>
        <p>
          Ist hier ein roter Punkt, hat die Kamera zuletzt nicht in die Cloud
          übertragen. Prüfen Sie zur Sicherheit, Datum und Uhrzeit der letzten
          Übertragung, rechts oberhalb des Bildes. Manchmal überträgt die Kamera
          schon wieder und der rote Punkt ist immer noch da, er schaltet erst
          nach einer gewissen Zeit wieder auf grün.
        </p>
        <p>
          Die wahrscheinlichste Ursache für eine BAU.CAMERA ohne Übertragung ist
          eine unterbrochene Stromversorgung. Bitte lassen Sie vor Ort
          überprüfen, ob die Stromversorgung der BAU.CAMERA sichergestellt ist.
          Wir können das aus der Ferne nicht überprüfen. Bei bestehender
          Versorgung bitte zusätzlich einmal den Stecker ziehen und nach ca. 15
          Sekunden wieder einstecken. Wenn die BAU.CAMERA trotz sichergestellter
          Stromversorgung und Neustart nicht funktioniert, nehmen Sie bitte
          Kontakt mit dem BAU.CAMERA Support auf.
        </p>

        <h3>Reihenfolge der Kameras und Projekte</h3>
        <p>
          Sie können die Reihenfolge der angezeigten Kameras und der Projekte
          durch Klicks auf das Lesezeichen-Symbol verändern. Jeder Nutzer kann
          diese Reihenfolge individuell einstellen. Projekte ohne verbundene
          Kameras werden automatisch ans Ende sortiert.{" "}
        </p>
      </div>
    </div>
  );
};

export default ProjectManual;
