import React, { useState } from "react";
import translate from "../../../i18n/translate";
import ArchiveManual from "./ArchiveManual";
import CameraManual from "./CameraManual";
import FAQManual from "./FAQManual";
import ProjectManual from "./ProjectManual";
import TimelapseManual from "./TimelapseManual";
import UsersPageManual from "./UsersPageManual";

const Index = () => {
  const [selectedTab, setSelectedTab] = useState("project");

  const logTypesOptions = [
    { label: translate("project-logs"), value: "project" },
    { label: translate("camera-logs"), value: "camera" },
    { label: translate("archive"), value: "archive" },
    { label: translate("timelapse"), value: "timelapse" },
    { label: translate("user-logs"), value: "user" },
    { label: "FAQ", value: "faq" },
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case "project":
        return <ProjectManual />;
      case "camera":
        return <CameraManual />;
      case "archive":
        return <ArchiveManual />;
      case "timelapse":
        return <TimelapseManual />;
      case "user":
        return <UsersPageManual />;
      default:
        return <FAQManual />;
    }
  };

  return (
    <div>
      <div className="log-event__cover">
        <h1>User Guide</h1>
      </div>
      <ul className="nav nav-tabs">
        {logTypesOptions.map((item) => {
          return (
            <li
              className="nav-item"
              key={item.label}
              onClick={() => setSelectedTab(item.value)}
            >
              <a
                className={
                  item.value === selectedTab ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                href="#"
              >
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
      <div>{renderContent()}</div>
    </div>
  );
};

export default Index;
