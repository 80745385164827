import React, { useState, useEffect, useRef } from "react";
import { Storage } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { getCameraForTimelapse } from "../helpers/manualQueries";
import { onUpdateCamera } from "../graphql/subscriptions";
import { updateCamera } from "../helpers/manualMutations";
import { MdClose, MdContentCopy } from "react-icons/md";
import { AiFillCaretRight } from "react-icons/ai";
import { FaCaretRight } from "react-icons/fa";
import "../styles/components/timelapse-popup.scss";
import ReactTooltip from "react-tooltip";
import { ButtonPrimary } from "./ButtonsPrimary";
import translate from "../i18n/translate";
import { useIntl } from "react-intl";
import ActionSnackbar from "./ActionSnackbar";

const TimelapsePopup = ({ open, onHide, cameraId, projectId }) => {
  const [cameraData, setCameraData] = useState({});
  const [timelapseUri, setTimelapseUri] = useState("");
  const [dayStatus, setDayStatus] = useState("#00B607");
  const [monthStatus, setMonthStatus] = useState("grey");
  const [weekStatus, setWeekStatus] = useState("grey");
  const [intervalFile, setIntervalFile] = useState("last_day");
  const [tooltipStatus, setTooltipStatus] = useState("");
  const [copyStatus, setCopyStatus] = useState(false); // New state for copy indicator

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState([]);

  const onUpdateCameraFunc = useRef();
  const intl = useIntl();

  const timelapseFile =
    projectId + "/" + cameraData.id + `/timelapses/${intervalFile}.mp4`;

  const handleSnackbarOpen = (hasLink) => {
    if (hasLink) {
      const message = translate("timelapse-copied");
      setSnackbarData([message, "info"]);
    } else {
      const message = translate("no-image-availabe");
      setSnackbarData([message, "warning"]);
    }
    setSnackbarOpen(!snackbarOpen);
  };

  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleCopyTimelapseLink = () => {
    const linkToCopy = cameraData.latestTimelapseLink; // Assuming `latestTimelapseLink` exists in cameraData
    if (linkToCopy) {
      navigator.clipboard
        .writeText(linkToCopy)
        .then(() => {
          setCopyStatus(true); // Show the indicato
          handleSnackbarOpen(true);
          setTimeout(() => {
            setCopyStatus(false);
          }, 2000);
        })
        .catch((err) => {
          console.error("Failed to copy the link:", err);
        });
    } else {
      console.warn("No timelapse link available to copy.");
    }
  };

  const handleChangeStatusColors = (interval, setStatusColor) => {
    switch (interval) {
      case "not created":
        setStatusColor("grey");
        break;
      case "requested":
        setStatusColor("#FFBB29");
        break;
      case "missing":
        setStatusColor("red");
        break;
      case "in progress":
        setStatusColor("#1181f2");
        break;
      case "available":
        setStatusColor("#00B607");
        break;
      case "outdated":
        setStatusColor("grey");
        break;
    }
  };

  const handleChangeTooltipsStatus = (interval) => {
    if (
      (interval === "lastWeekTimelapseStatus" &&
        cameraData.lastWeekTimelapseAvailability) ||
      (interval === "lastMonthTimelapseStatus" &&
        cameraData.lastMonthTimelapseAvailability)
    ) {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-not-created" }));
    } else if (cameraData.status !== "active") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-not-ready" }));
    } else if (cameraData[interval] === "not created") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-not-created" }));
    } else if (cameraData[interval] === "requested") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-not-requested" }));
    } else if (cameraData[interval] === "missing") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-missing" }));
    } else if (cameraData[interval] === "in progress") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-in-progress" }));
    } else if (cameraData[interval] === "available") {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-available" }));
    } else {
      setTooltipStatus(intl.formatMessage({ id: "time-lapse-outdated" }));
    }
    return;
  };

  const onGetCameraData = () => {
    if (cameraId) {
      API.graphql(
        graphqlOperation(getCameraForTimelapse, {
          id: cameraId,
        })
      )
        .then(({ data: { getCamera } }) => {
          setCameraData(getCamera);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    onGetCameraData();
    const intervalForCamera = setInterval(() => {
      onGetCameraData();
    }, 30000);
    return () => clearInterval(intervalForCamera);
  }, [cameraId]);

  useEffect(() => {
    if (cameraData) {
      handleChangeStatusColors(cameraData.lastDayTimelapseStatus, setDayStatus);
      handleChangeStatusColors(
        cameraData.lastWeekTimelapseStatus,
        setWeekStatus
      );
      handleChangeStatusColors(
        cameraData.lastMonthTimelapseStatus,
        setMonthStatus
      );
    }
  }, [cameraData]);

  useEffect(() => {
    onUpdateCameraFunc.current = API.graphql(
      graphqlOperation(onUpdateCamera)
    ).subscribe({
      next: ({
        value: {
          data: { onUpdateCamera },
        },
      }) => {
        setCameraData(onUpdateCamera);
      },
    });

    return () => {
      onUpdateCameraFunc.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      Storage.get(timelapseFile).then((res) => setTimelapseUri(res));
    }
  }, [timelapseFile, projectId]);

  const handleIntervalView = (interval, status) => {
    if (status === "available") {
      setIntervalFile(interval);
    }
  };

  const handleRequestTimelapse = (interval, id) => {
    API.graphql(
      graphqlOperation(updateCamera, {
        input: {
          id,
          [interval]: "requested",
        },
        sortDirection: "DESC",
      })
    );
  };

  const handleDownloadTimelapseVideo = () => {
    Storage.get(timelapseFile, { download: true }).then((result) => {
      let mimeType = result.ContentType;
      let fileName = projectId + "_" + cameraId + "_" + intervalFile + ".mp4";
      try {
        let blob = new Blob([result.Body], { type: mimeType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (exc) {
        throw exc;
      }
    });
  };

  const onClose = () => {
    onHide();
    setIntervalFile("last_day");
  };

  return (
    <Modal
      className="camera-popup"
      show={open}
      onHide={() => onClose()}
      centered
      styles={{ paddingRight: "0px" }}
    >
      <ActionSnackbar
        openBool={snackbarOpen}
        message={snackbarData[0]}
        opacity={true}
        severity={snackbarData[1]}
        onCloseSnackbar={onCloseSnackbar}
      />
      <div className="timelapse">
        <button className="timelapse__close" onClick={() => onClose()}>
          <MdClose size={22} />
        </button>
        <div className="timelapse__live-cam">
          <span className="timelapse__live-name">{cameraData.cameraName}</span>
          <span className="timelapse__live-text">
            <AiFillCaretRight color="#00B607" />
            &nbsp;
            {translate("timelapse")}
          </span>
        </div>
        <video
          src={timelapseUri}
          loop
          autoPlay
          width="100%"
          controls
          playsInline
        ></video>
        <div className="timelapse__button-box">
          <button
            onMouseOver={() =>
              handleChangeTooltipsStatus("lastDayTimelapseStatus")
            }
            data-tip={tooltipStatus}
            className="timelapse__button"
            style={{ background: dayStatus }}
            onClick={() =>
              handleIntervalView("last_day", cameraData.lastDayTimelapseStatus)
            }
          >
            {intervalFile === "last_day" && (
              <>
                <FaCaretRight color="#ffff" size={18} />
              </>
            )}
            {translate("day")}
          </button>
          <button
            data-tip={tooltipStatus}
            className="timelapse__button"
            onMouseOver={() =>
              handleChangeTooltipsStatus("lastWeekTimelapseStatus")
            }
            onClick={
              (cameraData.lastWeekTimelapseStatus === "not created" ||
                cameraData.lastWeekTimelapseStatus === "outdated") &&
              cameraData.lastWeekTimelapseAvailability
                ? () =>
                    handleRequestTimelapse(
                      "lastWeekTimelapseStatus",
                      cameraData.id,
                      cameraData.lastWeekTimelapseStatus
                    )
                : () =>
                    handleIntervalView(
                      "last_week",
                      cameraData.lastWeekTimelapseStatus
                    )
            }
            style={{ background: weekStatus }}
          >
            {intervalFile === "last_week" && (
              <>
                <FaCaretRight color="#ffff" size={18} />
              </>
            )}
            {translate("week")}
          </button>
          <button
            data-tip={tooltipStatus}
            className="timelapse__button"
            onMouseOver={() =>
              handleChangeTooltipsStatus("lastMonthTimelapseStatus")
            }
            onClick={
              (cameraData.lastMonthTimelapseStatus === "not created" ||
                cameraData.lastMonthTimelapseStatus === "outdated") &&
              cameraData.lastMonthTimelapseAvailability
                ? () =>
                    handleRequestTimelapse(
                      "lastMonthTimelapseStatus",
                      cameraData.id,
                      cameraData.lastMonthTimelapseStatus
                    )
                : () =>
                    handleIntervalView(
                      "last_month",
                      cameraData.lastMonthTimelapseStatus
                    )
            }
            style={{ background: monthStatus }}
          >
            {intervalFile === "last_month" && (
              <>
                <FaCaretRight color="#ffff" size={18} />
              </>
            )}
            {translate("month")}
          </button>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <ButtonPrimary
            title={translate("download")}
            type="submit"
            backgroundColor="transparent"
            color="#000"
            className=""
            onClick={handleDownloadTimelapseVideo}
          />
          {intervalFile === "last_day" && (
            <ButtonPrimary
              title={
                <>
                  <MdContentCopy key="copy" color="#1181f2" size={14} />
                  {translate("timelapse-copy")}
                </>
              }
              type="submit"
              backgroundColor={copyStatus ? "#00B607" : "transparent"}
              color="#000"
              className=""
              onClick={handleCopyTimelapseLink}
            />
          )}
        </div>
        <ReactTooltip place="top" type="dark" effect="float" />
      </div>
    </Modal>
  );
};

TimelapsePopup.propTypes = {
  open: PropTypes.bool,
  onHide: PropTypes.func,
  cameraName: PropTypes.string,
  cameraId: PropTypes.string,
  projectId: PropTypes.string,
};

export default TimelapsePopup;
