import React from "react";

const FAQManual = () => {
  return (
    <div className="log-event__container">
      <div className="log-event__container-inner">
        <h2>FAQ</h2>
        <h3>
          Wanneer ik een afbeelding vergroot in de projectweergave of in de
          player, ziet deze er soms een beetje wazig uit. Wat is hier de reden
          van?
        </h3>
        Alle afbeeldingen worden in de cloud weergegeven in een verkleinde
        voorbeeldgrootte om een snelle en goede gegevensverwerking te
        garanderen. De originele afbeeldingen worden niet gewijzigd en worden
        opgeslagen in hun originele grootte. Je ontvangt alle afbeeldingen in
        hun originele grootte zodra je ze hebt gedownload
        <h3>
          Ik wil de huidige foto van de camera op onze homepage zetten. Hoe kan
          ik dat doen?
        </h3>
        Klik in de "Camera"-weergave onder de speler op de knop "Afbeeldingslink
        kopiëren". De afbeeldingslink wordt gekopieerd en u kunt deze integreren
        in uw homepage of intranet. De link verwijst naar het laatste beeld
        zonder toegangsgegevens op te vragen. Deze link is alleen beschikbaar
        zodra de camera de eerste beelden heeft overgedragen. Deze functie is
        alleen beschikbaar voor hoofdgebruikers.
        <br />
        <h3>
          Er worden geen actuele afbeeldingen meer overgebracht naar de cloud.
          Wat kan er gedaan worden?
        </h3>
        De meest waarschijnlijke oorzaak is een onderbroken stroomvoorziening.
        Laat de stroomtoevoer naar de BAU.CAMERA ter plaatse controleren. We
        kunnen dit niet op afstand controleren. Als er een stroomvoorziening is,
        haal dan ook één keer de stekker uit het stopcontact en steek deze er na
        ongeveer 15 seconden weer in. Als de BAU.CAMERA niet werkt ondanks een
        veilige stroomvoorziening en herstart, neem dan contact op met support.
        <br />
        <h3>Ik heb de camera niet meer nodig. Wat moet ik doen?</h3>
        Als u de BAU.CAMERA niet langer nodig hebt, stuurt u hem gewoon naar ons
        terug. Een schriftelijke annulering op het einde van de huurperiode is
        niet meer nodig. Dit heeft geen invloed op de overeengekomen
        minimumtermijn. Onze apparatuur moet uiterlijk aan het einde van de
        lopende huurperiode aan ons worden geretourneerd om te voorkomen dat de
        huurperiode automatisch met een maand wordt verlengd. Zorg ervoor dat de
        apparatuur tijdig wordt ingeleverd, rekening houdend met de duur van het
        pakket. De einddatum van de overeengekomen huurperiode staat op de
        laatste factuur. Verpak de BAU.CAMERA-technologie zorgvuldig en stuur
        deze naar ons terug. De klant is verantwoordelijk voor schade als gevolg
        van onvoldoende verpakking. Voor uw eigen veiligheid, verzeker het
        pakket tegen verlies tot €2.500. Stuur ons indien mogelijk het
        trackingnummer van het pakket naar support@baucamera.com.
        <br />
        <h3>Adres voor retourzending:</h3>
        <p>NEXTFRAME Film + Media GmbH</p>
        <p>Östbergweg 14</p>
        30559 Hannover
        <br />
        <br />
        <h3>Ik wil mijn camera voor langere tijd huren. Wat moet ik doen?</h3>
        Je kunt de camera maandelijks automatisch verlengen, je hoeft niets te
        doen. Je ontvangt dan een maandelijkse factuur voor nog een maand huur.
        Als je liever elke zes maanden een factuur wilt ontvangen, neem dan
        contact op met Support.
        <br />
      </div>
    </div>
  );
};

export default FAQManual;
