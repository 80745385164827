/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          pinnedProject
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            pinnedProject
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      images {
        cameraId
        s3path
        __typename
      }
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          latestTimelapseLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          lastWeekTimelapseAvailability
          lastMonthTimelapseAvailability
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          pinnedProject
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        images {
          cameraId
          s3path
          __typename
        }
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            latestTimelapseLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            lastWeekTimelapseAvailability
            lastMonthTimelapseAvailability
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            pinnedProject
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      projects {
        items {
          id
          images {
            cameraId
            s3path
            __typename
          }
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCamera = /* GraphQL */ `
  query GetCamera($id: ID!) {
    getCamera(id: $id) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        images {
          cameraId
          s3path
          __typename
        }
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            latestTimelapseLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            lastWeekTimelapseAvailability
            lastMonthTimelapseAvailability
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            pinnedProject
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      latestTimelapseLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      lastWeekTimelapseAvailability
      lastMonthTimelapseAvailability
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCameras = /* GraphQL */ `
  query ListCameras(
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cameraName
        cameraAlias
        username
        password
        status
        project {
          id
          images {
            cameraId
            s3path
            __typename
          }
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        imagesByDate {
          items {
            id
            cameraID
            datetime
            date
            time
            intTime
            workday
            s3ObjectPathOriginal
            s3ObjectPathThumbnail
            s3ObjectPathBlurred
            s3ObjectPathBlurringPro
            blurringPro
            blurred
            masked
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        liveStream
        liveStreamLink
        latestPictureLink
        latestTimelapseLink
        protocol
        ftpLink
        rmsLink
        downloadImagesLink
        deleteAllImages
        changePassword
        hideCopyLink
        blurringEnabled
        blurringProEnabled
        maskEnabled
        timelapseEnabled
        testMode
        externalCamera
        lastDayTimelapseStatus
        lastWeekTimelapseStatus
        lastMonthTimelapseStatus
        lastWeekTimelapseAvailability
        lastMonthTimelapseAvailability
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cameraID
        datetime
        date
        time
        intTime
        workday
        s3ObjectPathOriginal
        s3ObjectPathThumbnail
        s3ObjectPathBlurred
        s3ObjectPathBlurringPro
        blurringPro
        blurred
        masked
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHistoricalArchive = /* GraphQL */ `
  query GetHistoricalArchive($id: ID!) {
    getHistoricalArchive(id: $id) {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHistoricalArchives = /* GraphQL */ `
  query ListHistoricalArchives(
    $filter: ModelHistoricalArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistoricalArchives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectName
        projectId
        companyName
        shareableLink
        archivingDate
        numberOfImages
        storageSpace
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        log
        user
        expire
        logType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCredentials = /* GraphQL */ `
  query GetCredentials($id: ID!) {
    getCredentials(id: $id) {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCredentialss = /* GraphQL */ `
  query ListCredentialss(
    $filter: ModelCredentialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredentialss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        googleApiKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCloudStatus = /* GraphQL */ `
  query GetCloudStatus($id: ID!) {
    getCloudStatus(id: $id) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCloudStatuss = /* GraphQL */ `
  query ListCloudStatuss(
    $filter: ModelCloudStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCloudStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayStatus
        displayMessage
        bannerStatus
        bannerMessage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listWeatherDatas = /* GraphQL */ `
  query ListWeatherDatas(
    $filter: ModelWeatherDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location_date
        utc_timestamp
        summary
        icon_id
        temperature
        precipitation
        wind
        wind_gust
        pressure
        cloud_cover
        location_key
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProjectsByName = /* GraphQL */ `
  query ListProjectsByName(
    $__typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByName(
      __typename: $__typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        images {
          cameraId
          s3path
          __typename
        }
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            latestTimelapseLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            lastWeekTimelapseAvailability
            lastMonthTimelapseAvailability
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            pinnedProject
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserProjectsByProject = /* GraphQL */ `
  query ListUserProjectsByProject(
    $projectID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProjectsByProject(
      projectID: $projectID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        projectID
        projects {
          id
          images {
            cameraId
            s3path
            __typename
          }
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          id
          username
          firstname
          lastname
          language
          email
          notificationsEnabled
          role
          imagesKeys
          dateRange
          timeRange
          filteredImages
          workday
          allImages
          archiving
          projects {
            nextToken
            __typename
          }
          status
          createdBy
          roleModifiedBy
          pendingDeletion
          archiveStatus
          recentProjects
          __typename
          createdAt
          updatedAt
          __typename
        }
        pinnedCams
        pinnedProject
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserProjectsByUser = /* GraphQL */ `
  query ListUserProjectsByUser(
    $userID: ID
    $projectID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProjectsByUser(
      userID: $userID
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        projectID
        projects {
          id
          images {
            cameraId
            s3path
            __typename
          }
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          id
          username
          firstname
          lastname
          language
          email
          notificationsEnabled
          role
          imagesKeys
          dateRange
          timeRange
          filteredImages
          workday
          allImages
          archiving
          projects {
            nextToken
            __typename
          }
          status
          createdBy
          roleModifiedBy
          pendingDeletion
          archiveStatus
          recentProjects
          __typename
          createdAt
          updatedAt
          __typename
        }
        pinnedCams
        pinnedProject
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompaniesByName = /* GraphQL */ `
  query ListCompaniesByName(
    $__typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesByName(
      __typename: $__typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCamerasByName = /* GraphQL */ `
  query ListCamerasByName(
    $__typename: String
    $cameraName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCamerasByName(
      __typename: $__typename
      cameraName: $cameraName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cameraName
        cameraAlias
        username
        password
        status
        project {
          id
          images {
            cameraId
            s3path
            __typename
          }
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        imagesByDate {
          items {
            id
            cameraID
            datetime
            date
            time
            intTime
            workday
            s3ObjectPathOriginal
            s3ObjectPathThumbnail
            s3ObjectPathBlurred
            s3ObjectPathBlurringPro
            blurringPro
            blurred
            masked
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        liveStream
        liveStreamLink
        latestPictureLink
        latestTimelapseLink
        protocol
        ftpLink
        rmsLink
        downloadImagesLink
        deleteAllImages
        changePassword
        hideCopyLink
        blurringEnabled
        blurringProEnabled
        maskEnabled
        timelapseEnabled
        testMode
        externalCamera
        lastDayTimelapseStatus
        lastWeekTimelapseStatus
        lastMonthTimelapseStatus
        lastWeekTimelapseAvailability
        lastMonthTimelapseAvailability
        __typename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
