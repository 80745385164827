import React from "react";

const UsersPageManual = () => {
  return (
    <div className="log-event__container">
      <div className="log-event__container-inner">
        <h2>Nutzer</h2>
        Hier können Sie weitere Personen, wie Kollegen oder Kunden zum Projekt
        einladen. Dazu wird nur der Namen und die E-Mail-Adresse des neuen
        Nutzers benötigt.
        <br />
        <br />
        Unter Rolle legen Sie fest, ob der neue Nutzer alle Bilder des Archivs
        sehen darf (Nutzer) oder ob er nur das jeweils aktuelle Bild sehen kann
        (limitierter Nutzer).
        <br />
        <br />
        Unterhalb dieses Auswahlfelds können Sie dem neuen Nutzer ein oder
        mehrere ihrer Projekte zuweisen. Ein Klick auf das jeweilige Projekt
        ändert die Farbe in blau, das Projekt is so ausgewählt.
        <br />
        <br />
        Sobald sie auf „+ Hinzufügen“ klicken, erhält der neue Nutzer eine
        E-Mail mit seinem persönlichen Zugang zu Ihren ausgewählten Projekten.
        <br />
        <br />
        Auch Nutzer, die schon einen Zugang haben, können Sie Ihrem Projekt
        zuordnen.
      </div>
    </div>
  );
};

export default UsersPageManual;
