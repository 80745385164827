import React, { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import "../../styles/pages/projects/projects.scss";
import { listCompanys } from "../../graphql/queries";
import {
  listUsers,
  listProjects,
  listProjectsByName,
  listProjectsOptions,
  getRecentProjectsByUser,
} from "../../helpers/manualQueries";
import {
  onCreateProject,
  onUpdateProject,
  onDeleteProject,
  onUpdateCamera,
  onDeleteCamera,
} from "../../graphql/subscriptions";
import { updateUser, updateUserProject } from "../../graphql/mutations";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import Select from "react-select";
import {
  FaMapMarkerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaThList,
} from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import Card from "../../components/Card";
import GoogleMap from "../../components/GoogleMap";
import AddEditProject from "./AddEditProject";
import { Auth } from "aws-amplify";
import translate from "../../i18n/translate";
import Loader from "../../components/Loader";
import DropdownInput from "../../components/Dropdown";
import { ProjectImage } from "./ProjectImage";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";

const Projects = (props) => {
  const [projects, setProjects] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userID, setUserID] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemPerPage] = useState(36);
  const [listCompanysData, setListCompanysData] = useState([]);
  const [mapView, setMapview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, openModal] = useState({
    editProject: false,
    show: false,
    id: "",
  });
  const [Role, setRole] = useState("");
  const [userName, setUserName] = useState("");
  const [filter, setFilter] = useState(translate("projects"));
  let [sortedProjects] = useState(null);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [projectsListOptions, setProjectsListOptions] = useState([]);
  const [theme, setTheme] = useState("light");

  let fetchListProjectsFunc = useRef();
  const setHeight = () => {
    const height = localStorage.getItem("height");
    document.documentElement.scrollTop = height;
    localStorage.removeItem("height");
  };

  let onCreateProjectFunc = useRef();
  let onUpdateProjectFunc = useRef();
  let onDeleteProjectFunc = useRef();
  let onUpdateProjectCamera = useRef();
  let onDeleteProjectCamera = useRef();

  useEffect(() => {
    TempuseEffectFunc.current();
    return () => {
      onCreateProjectFunc.current.unsubscribe();
      onUpdateProjectFunc.current.unsubscribe();
      onDeleteProjectFunc.current.unsubscribe();
      onUpdateProjectCamera.current.unsubscribe();
      onDeleteProjectCamera.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setTheme(window.localStorage.getItem("theme"));
  }, [window.localStorage.getItem("theme")]);

  const TempuseEffect = () => {
    if (props?.match?.params?.page) {
      setCurrentPage(parseInt(props?.match?.params?.page) - 1);
    }
    onCreateProjectFunc.current = API.graphql(
      graphqlOperation(onCreateProject)
    ).subscribe({
      next: ({
        value: {
          data: { onCreateProject },
        },
      }) => {
        setProjects((previousData) => [...previousData, onCreateProject]);
      },
    });

    onUpdateProjectFunc.current = API.graphql(
      graphqlOperation(onUpdateProject)
    ).subscribe({
      next: (updateProjectData) => {
        const updatedProject = updateProjectData?.value?.data?.onUpdateProject;
        setProjects((previousData) =>
          previousData.map((project) => {
            if (project.id === updatedProject.id) {
              return (project = updatedProject);
            } else return project;
          })
        );
      },
    });

    onDeleteProjectFunc.current = API.graphql(
      graphqlOperation(onDeleteProject)
    ).subscribe({
      next: (deletedProjectData) => {
        const updatedProject = deletedProjectData.value.data.onDeleteProject;
        setProjects((previousData) =>
          previousData.filter((project) => {
            if (project.id !== updatedProject.id) return project;
            return false;
          })
        );
      },
    });

    onUpdateProjectCamera.current = API.graphql(
      graphqlOperation(onUpdateCamera)
    ).subscribe({
      next: ({
        value: {
          data: { onUpdateCamera },
        },
      }) => {
        setProjects((projs) => {
          if (onUpdateCamera.project) {
            let cameraProjectId = onUpdateCamera?.project?.id;
            let allProjects = projs.filter((project) => {
              if (project.id === cameraProjectId) {
                project.cameras.items.push(onUpdateCamera);
                return project;
              } else return project;
            });
            return allProjects;
          } else {
            let allProjects = projs.map((project) => {
              let allCameras = project?.cameras?.items.filter((camera) => {
                if (camera.id !== onUpdateCamera.id) return camera;
                else return false;
              });
              project.cameras.items = allCameras;
              return project;
            });
            return allProjects;
          }
        });
      },
    });

    onDeleteProjectCamera.current = API.graphql(
      graphqlOperation(onDeleteCamera)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteCamera },
        },
      }) => {
        setProjects((projs) => {
          let allProjects = projs.map((project) => {
            if (project.id === onDeleteCamera?.project?.id) {
              let allCameras = project.cameras.items.filter((camera) => {
                if (camera?.id !== onDeleteCamera?.id) return camera;
                else return false;
              });
              project.cameras.items = allCameras;
              return project;
            } else return project;
          });
          return allProjects;
        });
      },
    });
  };

  let TempuseEffectFunc = useRef();
  TempuseEffectFunc.current = TempuseEffect;

  const getRole = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    const role =
      userDetails?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    setRole(role.toString().toLowerCase());
    setUserName(userDetails?.username);
  };

  const filterOptions = {
    company: listCompanysData.map((company) => ({
      label: company.name,
      value: company.id,
    })),
    projects: projectsListOptions.map((project) => ({
      value: project.name,
      label: project.name,
    })),
    status: [
      { value: false, label: "Active" },
      { value: true, label: "Completed" },
    ],
  };

  const fetchListProjects = async () => {
    setLoader(true);
    if (filter?.props?.id === "company" && !!filterValue) {
      API.graphql(
        graphqlOperation(listProjects, {
          filter: { companyID: { eq: filterValue.value } },
          limit: 10000,
          sortDirection: "DESC",
        })
      )
        .then(
          ({
            data: {
              listProjects: { items },
            },
          }) => {
            setProjects(items);
            setLoader(false);
            setHeight();
          }
        )
        .catch(() => {
          setLoader(false);
        });
    } else if (filter?.props?.id === "projects" && !!filterValue) {
      API.graphql(
        graphqlOperation(listProjects, {
          filter: { name: { eq: filterValue.value } },
          limit: 10000,
          sortDirection: "DESC",
        })
      )
        .then(
          ({
            data: {
              listProjects: { items },
            },
          }) => {
            setProjects(items);
            setLoader(false);
            setHeight();
          }
        )
        .catch(() => {
          setLoader(false);
        });
    } else if (filter?.props?.id === "status" && !!filterValue) {
      API.graphql(
        graphqlOperation(listProjectsByName, {
          limit: 10000,
          filter: { endedAt: { attributeExists: filterValue.value } },
        })
      )
        .then(
          ({
            data: {
              listProjectsByName: { items },
            },
          }) => {
            setProjects(items);
            setLoader(false);
            setHeight();
          }
        )
        .catch(() => {
          setLoader(false);
        });
    } else {
      API.graphql(
        graphqlOperation(listProjectsByName, { limit: 36, nextToken })
      )
        .then(
          ({
            data: {
              listProjectsByName: { items, nextToken },
            },
          }) => {
            setProjects(items);
            setNextNextToken(nextToken);
            setLoader(false);
            setHeight();
          }
        )
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const fetchUserProjects = async () => {
    setLoader(true);
    try {
      const userProjectsData = await API.graphql(
        graphqlOperation(listUsers, {
          filter: { username: { eq: Auth.user.username } },
          sortDirection: "DESC",
          limit: 10000,
        })
      );
      const data = userProjectsData.data.listUsers.items[0].projects.items;
      setUserID(userProjectsData.data.listUsers.items[0].id);
      if (
        Role === "clientall" ||
        Role === "superuser" ||
        Role === "clientcamonly"
      ) {
        setUserProjects(data);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      throw err;
    }
  };

  const fetchListCompany = async () => {
    if (Role === "admin" || Role === "crew") {
      try {
        const listCompanies = await API.graphql(
          graphqlOperation(listCompanys, {
            limit: 10000,
          })
        );
        setListCompanysData(listCompanies.data.listCompanys.items);
      } catch (err) {
        alert(err);
      }
    }
  };

  const fetchListProjectsOptions = async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(listProjectsOptions, { limit: 10000 })
      );
      setProjectsListOptions(data.data.listProjectsByName.items);
    } catch (err) {
      throw err;
    }
  };

  const updateUserRecentProjects = (projectID) => {
    try {
      API.graphql(
        graphqlOperation(getRecentProjectsByUser, {
          filter: { username: { eq: userName } },
        })
      ).then(({ data }) => {
        const recentProjectsIDs = data.listUsers.items[0]?.recentProjects;
        if (!recentProjectsIDs.includes(projectID)) {
          try {
            API.graphql(
              graphqlOperation(updateUser, {
                input: {
                  id: userID,
                  recentProjects: projectID,
                },
              })
            );
          } catch (err) {
            throw err;
          }
        }
      });
    } catch (err) {
      throw err;
    }
  };

  fetchListProjectsFunc.current = fetchListProjects;
  const [updatedProjects, setUpdatedProjects] = useState(false);

  useEffect(() => {
    getRole();
    fetchListProjectsOptions();
  }, []);

  useEffect(() => {
    fetchListCompany();
    fetchUserProjects();
  }, [Role]);

  useEffect(() => {
    if (updatedProjects) {
      setTimeout(() => {
        fetchUserProjects();
      }, 500);

      setUpdatedProjects(false);
    }
  }, [updatedProjects]);

  useEffect(() => {
    if (Role === "admin" || Role == "crew") fetchListProjectsFunc.current();
  }, [nextToken, filterValue, Role]);

  function sortProjects(userProjects) {
    function hasEndDate(cameraIDs) {
      const parsedCameraIDs = JSON.parse(cameraIDs);
      return Object.entries(parsedCameraIDs).some(
        ([key, dates]) => key !== "undefined" && dates.includes("end_date")
      );
    }

    const pinnedProjects = [];
    const projectsWithEndDate = [];
    const projectsWithoutEndDate = [];

    userProjects.forEach((project) => {
      const {
        pinnedProject,
        projects: { cameraIDs, name },
      } = project;
      console.log(
        `Processing project: ${name}, pinnedProject: ${pinnedProject}`
      );

      if (pinnedProject) {
        pinnedProjects.push(project);
        console.log(`Adding to pinnedProjects: ${name}`);
      } else if (hasEndDate(cameraIDs)) {
        projectsWithEndDate.push(project);
        console.log(`Adding to projectsWithEndDate: ${name}`);
      } else {
        projectsWithoutEndDate.push(project);
        console.log(`Adding to projectsWithoutEndDate: ${name}`);
      }
    });

    const alphabeticalSort = (a, b) =>
      a.projects.name.localeCompare(b.projects.name);

    pinnedProjects.sort(alphabeticalSort);
    projectsWithEndDate.sort(alphabeticalSort);
    projectsWithoutEndDate.sort(alphabeticalSort);

    const combinedList = [
      ...pinnedProjects,
      ...projectsWithEndDate,
      ...projectsWithoutEndDate,
    ];

    console.log("Final combined list", combinedList);

    return combinedList;
  }

  const updatePinnedCams = (project) => {
    API.graphql(
      graphqlOperation(updateUserProject, {
        input: {
          id: project.id,
          pinnedProject: project.pinnedProject === true ? false : true,
        },
      })
    );
    setUpdatedProjects(true);
  };

  useEffect(() => {
    if (filter.props.id === "company" && !!filterValue)
      fetchListProjectsFunc.current();
  }, [filterValue, filter.props.id]);

  const indexOfLastItem = (currentPage + 1) * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  if (Role === "admin" || Role == "crew") {
    sortedProjects = projects;
    // sortedProjects = projects.sort((a, b) =>
    //     a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    // );
  } else {
    sortedProjects = sortProjects(userProjects);
    // sortedProjects = userProjects.sort((a, b) =>
    //     a.projects.name > b.projects.name ? 1 : b.projects.name > a.projects.name ? -1 : 0
    // );
  }

  const currentItem = sortedProjects.slice(indexOfFirstItem, indexOfLastItem);

  const {
    match: { params },
  } = props;

  const handleNextPage = () => {
    setPreviousTokens([...previousTokens, nextToken]);
    setNextToken(nextNextToken);
  };

  const handlePreviousPage = () => {
    setNextToken(previousTokens.pop());
  };

  useEffect(() => {
    if (props.location.state) {
      setNextToken(props.location.state?.nextToken);
      setPreviousTokens(props.location.state?.previousTokens);
      window.history.replaceState({}, document.title);
    }
  }, []);

  return (
    <div className="projects">
      <div className="projects__cover">
        <h1 className="projects__header">{translate("projects")}</h1>

        <div className="projects__filter-left projects__filter-left--mobile">
          <button
            className="project__filter-btn"
            style={
              mapView
                ? { backgroundColor: "#ffff" }
                : { backgroundColor: "#1181F2" }
            }
            onClick={() => setMapview(false)}
          >
            <FaThList color={mapView ? "#1181F2" : "#ffff"} size={16} />
          </button>
          <button
            className="project__filter-btn"
            style={
              mapView
                ? { backgroundColor: "#1181F2" }
                : { backgroundColor: "#ffff" }
            }
            onClick={() => setMapview(true)}
          >
            <FaMapMarkerAlt color={mapView ? "#ffff" : "#1181F2"} size={16} />
          </button>
        </div>

        {Role === "admin" || Role === "crew" ? (
          <ButtonPrimary
            onClick={() =>
              openModal({
                editProject: false,
                show: true,
                editProjectData: {},
              })
            }
            backgroundColor="#ffff"
            color="#000000"
            title={translate("new-project")}
          />
        ) : (
          <button
            onClick={() => window.location.reload()}
            className="project__cover-reload-btn"
          >
            <IoMdRefresh size={25} />
            &nbsp;&nbsp; {translate("reload")}
          </button>
        )}
      </div>
      {!Role && (
        <div className="projects__reload-header">
          <button
            onClick={() => window.location.reload()}
            className="project__cover-reload-btn"
          >
            <IoMdRefresh size={25} />
            &nbsp;&nbsp; {translate("reload")}
          </button>
        </div>
      )}

      {Role && (
        <div className="projects__filter">
          <div className="projects__filter-left project projects__filter-left--desktop">
            <FaThList
              color={mapView ? "grey" : "#1181F2"}
              size={24}
              onClick={() => setMapview(false)}
              style={{ cursor: "pointer" }}
            />
            <FaMapMarkerAlt
              color={mapView ? "#1181F2" : "grey"}
              size={22}
              onClick={() => setMapview(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {(Role == "admin" || Role == "crew") && (
            <div className="projects__subContainer">
              <div className="projects__header-btn">
                <div className="projects__dropdown-btn">
                  <DropdownInput
                    name="filter"
                    item={[
                      translate("company"),
                      translate("projects"),
                      translate("status"),
                    ]}
                    label={translate("filter-by")}
                    title={
                      filter?.props?.id
                        ? translate(filter?.props?.id)
                        : translate("projects")
                    }
                    iconSize={16}
                    onSelect={(val) => {
                      setFilterValue(null);
                      setFilter(val);
                    }}
                  />
                </div>
                <Select
                  options={filterOptions[filter?.props?.id]}
                  placeholder=""
                  onChange={(val) => setFilterValue(val)}
                  value={filterValue}
                />
                <button
                  onClick={() => setFilterValue(null)}
                  className="overview__reset-btn"
                >
                  <IoMdRefresh color="#1181F2" size={28} />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {mapView && (Role === "admin" || Role === "crew") ? (
        <div className="project__google-map">
          <GoogleMap projects={projectsListOptions} role={Role} />
        </div>
      ) : mapView ? (
        <div className="project__google-map">
          <GoogleMap projects={userProjects} role={Role} />
        </div>
      ) : (
        <Row className="thumbnails-row">
          {loader ? (
            <Loader />
          ) : currentItem.length ? (
            Role === "admin" || Role === "crew" ? (
              currentItem.map((project) => (
                <Col
                  key={project.id}
                  sm={12}
                  md={6}
                  lg={3}
                  className="thumbnails__col"
                >
                  <div className="projects__thumnail">
                    <Card
                      onClick={() => {
                        updateUserRecentProjects(project.id);
                        localStorage.setItem(
                          "height",
                          document.documentElement.scrollTop
                        );
                        props.history.push({
                          pathname: `/projects/${project.id}`,
                          state: {
                            nextToken,
                            previousTokens,
                          },
                        });
                      }}
                    >
                      <div className="project__thumbnail-img">
                        {project.cameras?.items?.length ? (
                          project.cameras.items
                            .slice(0, 3)
                            .map((camera, index) => (
                              <ProjectImage
                                key={index}
                                cameraIDs={project?.cameraIDs}
                                camera={camera}
                                length={
                                  project?.cameras?.items.slice(0, 3).length
                                }
                              />
                            ))
                        ) : (
                          <img
                            height="100px"
                            src={
                              theme === "dark"
                                ? require("../../assets/images/greycam-dark.png")
                                : require("../../assets/images/greycam.png")
                            }
                            className="default_img"
                            alt="Default Camera"
                          />
                        )}
                      </div>
                      <div className="project__thumbnails-info">
                        <h6>{project.name}</h6>
                        <p>{project?.location}</p>
                        <p>{project?.company?.name}</p>
                      </div>
                    </Card>
                  </div>
                </Col>
              ))
            ) : (
              currentItem.map((project) => (
                <Col
                  key={project.id}
                  sm={12}
                  md={6}
                  lg={3}
                  className="thumbnails__col"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span />
                    {(Role === "clientall" ||
                      Role === "superuser" ||
                      Role === "clientcamonly") && (
                      <BookmarkAddedIcon
                        sx={{
                          fontSize: "24px",
                          marginLeft: "5px",
                          cursor: "pointer",

                          color: project.pinnedProject ? "#1181f2" : "#cacaca",
                        }}
                        onClick={() => {
                          updatePinnedCams(project);
                        }}
                      />
                    )}
                  </div>

                  <div className="projects__thumnail">
                    <Card
                      onClick={() => {
                        localStorage.setItem(
                          "height",
                          document.documentElement.scrollTop
                        );
                        props.history.push({
                          pathname: `/projects/${project.projects.id}`,
                        });
                      }}
                    >
                      <div className="project__thumbnail-img">
                        {project.projects?.cameras?.items?.length ? (
                          project?.projects?.cameras?.items
                            .slice(0, 3)
                            .map((camera, index) => {
                              return (
                                <ProjectImage
                                  cameraIDs={project?.projects?.cameraIDs}
                                  key={index}
                                  camera={camera}
                                  length={
                                    project?.cameras?.items.slice(0, 3).length
                                  }
                                />
                              );
                            })
                        ) : (
                          <img
                            height="100px"
                            src={require("../../assets/images/greycam.png")}
                            className="default_img"
                            alt="Default Camera"
                          />
                        )}
                      </div>
                      <div className="project__thumbnails-info">
                        <h6>{project.projects?.name}</h6>
                        <p>{project?.projects?.location}</p>
                        <p>{project?.projects?.company?.name}</p>
                      </div>
                    </Card>
                  </div>
                </Col>
              ))
            )
          ) : (
            <h2 className="projects__thumbnail-empty">
              {translate("no-projects")}
            </h2>
          )}
        </Row>
      )}

      {(Role === "admin" || Role === "crew") && !filterValue ? (
        <div className="pagination-container">
          <button
            onClick={handlePreviousPage}
            className="pagination-arrow"
            disabled={previousTokens?.length === 0}
          >
            <FaChevronLeft
              color={previousTokens?.length === 0 ? "lightgrey" : "#1181F2"}
              size={14}
              className="pagination__btn mr-2"
            />{" "}
          </button>
          <div className="pagination__page">
            Page {previousTokens.length + 1}
          </div>
          <button
            onClick={handleNextPage}
            className="pagination-arrow"
            disabled={!nextNextToken}
          >
            <FaChevronRight
              color={!nextNextToken ? "lightgrey " : "#1181F2"}
              size={14}
              className="pagination__btn ml-2"
            />
          </button>
        </div>
      ) : null}

      <AddEditProject
        modal={modal}
        onHide={() =>
          openModal({
            editProject: false,
            show: false,
            id: "",
          })
        }
      />
    </div>
  );
};

export default Projects;
