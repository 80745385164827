import React from "react";
import helpTimelapse from "./assets/hilfe_zeitraffer.png";

const TimelapseManual = () => {
  return (
    <div className="log-event__container">
      <div className="log-event__container-inner">
        <h2>Time-lapse online</h2>
        <h3>Time lapse</h3>
        Here you can see the time-lapse of the last day, it plays automatically.
        The production of the time-lapse of the last week and the last month can
        be ordered by clicking on the respective button. As soon as the
        time-lapse is available, the color of the button changes to green. Each
        time-lapse can also be downloaded.
        <br />
        <br />
        <img src={helpTimelapse} alt="help_project" />
        <h3>Timelapse on Website</h3>
        <p>
          If you would like to embed the daily timelapse on your website, click
          "Copy link to daily timelapse" and use the link on your site. Links
          for the timelapses of the last week and last month are not available.
        </p>
      </div>
    </div>
  );
};

export default TimelapseManual;
