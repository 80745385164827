import React from "react";
import helpProject from "./assets/hilfe_projekt.png";

const ProjectManual = () => {
  return (
    <div className="log-event__container">
      <div className="log-event__container-inner">
        <h2>Project</h2>

        <p>
          Here you can see all the cameras in the selected project. The date and
          time of the last image transferred is shown above the image on the
          right.
        </p>

        <img src={helpProject} alt="help_project" />

        <h3>Navigation</h3>
        <p>
          Click on the picture to open the player. Here you can browse through
          all the images in the archive. The buttons 'Archive', depending on the
          booking also 'Live' and 'Time lapse', will take you to the respective
          pages.
        </p>

        <h3>Three parallel lines</h3>
        <p>
          By clicking on this button, the images from all cameras are displayed
          as large as possible, giving you a good overview of the current
          situation. For technical reasons, all images are only displayed in a
          reduced preview size in the cloud; the original images are saved
          unchanged in their original size. You can download images in their
          original size.
        </p>

        <h3>Green dot</h3>
        <p>
          A green dot on the left above the image shows that the camera is
          active and images are being transferred to the BAU.CAMERA Cloud.
        </p>

        <h3>Red dot</h3>
        <p>
          If there is a red dot here, the camera has not transferred to the
          cloud recently. To be on the safe side, check the date and time of the
          last transfer on the right above the image. Sometimes the camera is
          already transmitting again and the red dot is still there, it only
          switches back to green after a certain time.
        </p>
        <p>
          The most likely cause of a BAU.CAMERA without transmission is an
          interrupted power supply. Please have the power supply to the
          BAU.CAMERA checked on site. We cannot check this remotely. If there is
          a power supply, please also unplug the plug once and plug it back in
          after approx. 15 seconds. If the BAU.CAMERA does not work despite a
          secure power supply and restart, please contact BAU.CAMERA Support.
        </p>

        <h3>Order of Cameras and Projects</h3>
        <p>
          You can adjust the order of displayed cameras and projects by clicking
          the bookmark icon. Each user can set this order individually. Projects
          without connected cameras are automatically sorted to the end.{" "}
        </p>
      </div>
    </div>
  );
};

export default ProjectManual;
