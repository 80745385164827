import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listUsers } from "../../graphql/queries";
import {
  getProject,
  listUserProjectsByUser,
} from "../../helpers/manualQueries";
import {
  updateCamera,
  deleteUserProject,
  createHistoricalArchive,
  createEvent,
  updateProject,
  updateUser,
  updateUserProject,
} from "../../graphql/mutations";
import {
  onUpdateProject,
  onUpdateCamera,
  onDeleteCamera,
} from "../../graphql/subscriptions";
import PropTypes from "prop-types";
import "../../styles/pages/projects/single-project.scss";
import { FaRedoAlt, FaBars } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import { Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import DeleteUser from "../../components/DeleteModal";
import AddEditProject from "./AddEditProject";
import SingleProjectCard from "./SingleProjectCard";
import Switch from "react-switch";
import { Auth } from "aws-amplify";
import translate from "../../i18n/translate";
import Loader from "../../components/Loader";
import LiveVideoPopup from "../../components/LiveVideoPopup";
import TimelapsePopup from "../../components/TimelapesPopup";
import SingleProjectImagePopup from "../Projects/SingleProjectImagePopup";
import { ButtonPrimary } from "../../components/ButtonsPrimary";

const SingleProject = (props) => {
  const [project, setProject] = useState("");
  const [cameraId, setCameraId] = useState(null);
  const [listView, setListview] = useState(false);
  const [checked, setChecked] = useState(true);
  const [deleteProjectState, openDeleteProject] = useState({
    name: "",
    show: false,
    id: "",
  });
  const [loader, setLoader] = useState(false);
  const [modal, openModal] = useState({
    editProject: false,
    show: false,
    id: "",
  });
  const [cameraPopUp, setCameraPopUp] = useState(false);
  const [Role, setRole] = useState("");
  const [hasID, setHasId] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemPerPage] = useState(12);
  const [dataLoader, setDataLoader] = useState(false);
  const [userID, setUserID] = useState(null);
  const [livePopUp, setLivePopUp] = useState(false);
  const [liveState, setLiveState] = useState({
    cameraName: "",
    liveStreamLink: "",
  });
  const [endDelete, setEndDelete] = useState("END");
  const [cameraIDs, setCameraIDs] = useState({});
  const events = {
    log: "",
    user: Auth.user.username,
    expire: "",
    logType: "project",
  };
  const [popupCam, setPopupCam] = useState();
  const [openTimelapse, setOpenTimelapse] = useState(false);
  const onUpdateProjectFunc = useRef();
  const onUpdateProjectCamera = useRef();
  const onDeleteCam = useRef();
  const fetchProjectFunction = useRef();
  const tempfetchProjectFunc = useRef();
  const tempuseEffectFunc = useRef();
  const [pinnedCamsIDs, setPinnedCamesIDs] = useState([]);
  const [pinnedCams, setPinnedCams] = useState([]);
  const [unPinnedCams, setUnpinnedCams] = useState([]);
  const [userProjectID, setUserProjectID] = useState("");
  const [isPinningCams, setIsPinningCams] = useState(false);
  const [theme, setTheme] = useState("light");
  const [imageURI, setImageURI] = useState("");
  const [imagesURI, setImagesURI] = useState([]);

  let now = (Date.now() + 15552000000) / 1000;
  events.expire = Math.round(now);

  const projectCamLength = project?.cameras?.items.length;
  useEffect(() => {
    if (!projectCamLength) {
      setEndDelete("Archive");
    } else {
      setEndDelete("END");
    }
  }, [projectCamLength]);

  const fetchProjectFunc = () => {
    fetchProject(true);
    getRole();
  };
  fetchProjectFunction.current = fetchProjectFunc;

  const tempfetchProject = () => {
    fetchProject(false);
  };
  tempfetchProjectFunc.current = tempfetchProject;

  useEffect(() => {
    fetchProjectFunction.current();
    let interval = setInterval(() => {
      tempfetchProjectFunc.current();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    tempuseEffectFunc.current();
    return () => {
      onUpdateProjectFunc.current.unsubscribe();
      onUpdateProjectCamera.current.unsubscribe();
      onDeleteCam.current.unsubscribe();
    };
  }, []);

  const TempuseEffect = () => {
    onUpdateProjectFunc.current = API.graphql(
      graphqlOperation(onUpdateProject)
    ).subscribe({
      next: ({
        value: {
          data: { onUpdateProject },
        },
      }) => {
        if (props?.match?.params?.id === onUpdateProject?.id) {
          setProject(onUpdateProject);
        }
      },
    });

    onUpdateProjectCamera.current = API.graphql(
      graphqlOperation(onUpdateCamera)
    ).subscribe({
      next: ({
        value: {
          data: { onUpdateCamera },
        },
      }) => {
        if (onUpdateCamera?.project) {
          if (props?.match?.params?.id === onUpdateCamera?.project.id) {
            setProject((proj) => {
              let updatedProject = { ...proj };
              if (onUpdateCamera.project.id === proj.id) {
                let isCameraMatched = false;
                let allCameras = proj?.cameras?.items.map((camera) => {
                  if (onUpdateCamera.id === camera.id) {
                    isCameraMatched = true;
                    return onUpdateCamera;
                  } else return camera;
                });
                if (!isCameraMatched) {
                  allCameras.push(onUpdateCamera);
                }
                updatedProject.cameras.items = allCameras;
                return updatedProject;
              } else return updatedProject;
            });
          }
        } else {
          setProject((proj) => {
            let allCameras = proj.cameras.items.filter((camera) => {
              if (camera.id !== onUpdateCamera.id) return camera;
              else return false;
            });
            let updatedProject = { ...proj };
            updatedProject.cameras.items = allCameras;
            return updatedProject;
          });
        }
      },
    });

    onDeleteCam.current = API.graphql(
      graphqlOperation(onDeleteCamera)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteCamera },
        },
      }) => {
        setProject((proj) => {
          let allCameras = proj.cameras.items.filter((camera) => {
            if (camera.id !== onDeleteCamera.id) return camera;
            else return false;
          });
          let updatedProject = { ...proj };
          updatedProject.cameras.items = allCameras;
          return updatedProject;
        });
      },
    });
  };
  tempuseEffectFunc.current = TempuseEffect;

  useEffect(() => {
    if (userID) {
      fetchUserProject();
    }
  }, [userID]);

  const fetchUserProject = () => {
    if (
      Role === "clientall" ||
      Role === "superuser" ||
      Role === "clientcamonly"
    ) {
      try {
        API.graphql(
          graphqlOperation(listUserProjectsByUser, {
            userID: userID,
            projectID: { eq: props?.match?.params?.id },
          })
        ).then((res) => {
          setUserProjectID(res.data.listUserProjectsByUser.items[0].id);
          if (res.data.listUserProjectsByUser.items[0].pinnedCams) {
            setPinnedCamesIDs(
              res.data.listUserProjectsByUser.items[0].pinnedCams
            );
          } else {
            setPinnedCamesIDs([]);
          }
        });
      } catch (e) {
        throw e;
      }
    }
  };

  const fetchProject = (loader) => {
    setDataLoader(loader);
    try {
      API.graphql(
        graphqlOperation(getProject, { id: props?.match?.params?.id })
      )
        .then(({ data: { getProject } }) => {
          setProject(getProject);
          const decideEndOrDelete = getProject?.cameras?.items?.length;
          setEndDelete(decideEndOrDelete ? "END" : "DELETE");
          setDataLoader(false);
          if (getProject) {
            if (getProject.cameraIDs) {
              setCameraIDs(JSON.parse(getProject.cameraIDs));
            }
          } else {
            setHasId(true);
          }
        })
        .catch((err) => {
          setDataLoader(false);
          throw err;
        });
    } catch (e) {
      throw e;
    }
    API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          username: {
            eq: Auth.user.username,
          },
        },
        limit: 10000,
      })
    ).then(
      ({
        data: {
          listUsers: { items },
        },
      }) => {
        setUserID(items[0].id);
        setChecked(items[0].notificationsEnabled);
      }
    );
  };

  const onHide = () => {
    openDeleteProject({
      name: "",
      show: false,
      id: "",
    });
    setLoader(false);
  };

  const getRole = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    const role =
      userDetails?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    setRole(role.toString().toLowerCase());
  };

  const deleteProjectFunc = () => {
    try {
      events.log = "A project " + deleteProjectState.name + " has been deleted";
      const event = { ...events };
      API.graphql(graphqlOperation(createEvent, { input: event }));
    } catch (err) {
      throw err;
    }
    API.graphql(
      graphqlOperation(createHistoricalArchive, {
        input: {
          archivingDate: new Date().toISOString(),
          projectName: project.name,
          companyName: project.company.name,
          shareableLink: project.shareableLink,
          numberOfImages: 0,
          storageSpace: "pending...",
          projectId: project.id,
        },
      })
    )
      .then(() => {
        setLoader(false);
        openDeleteProject({ name: "", show: false });
        props.history.push("/projects");
      })
      .catch(({ errors }) => {
        setLoader(false);
        setDeleteError(errors[0]?.message);
      });
    API.graphql(
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          pendingDeletion: true,
        },
      })
    );
  };

  const deleteCompleteProjectFunc = () => {
    setLoader(true);
    if (project?.users?.items?.length) {
      project.users.items.forEach((user, index) => {
        API.graphql(
          graphqlOperation(deleteUserProject, { input: { id: user.id } })
        )
          .then(() => {
            if (project.users.items.length === index + 1) {
              deleteProjectFunc();
            }
          })
          .catch(({ errors }) => {
            setLoader(false);
            setDeleteError(errors[0]?.message);
          });
      });
    } else {
      deleteProjectFunc();
    }
  };

  const endProjectFunc = () => {
    setLoader(true);
    const currentDateTime =
      moment(new Date()).toISOString(true).split("+")[0] + "Z";
    if (project.cameras.items.length) {
      let newCameraIDs = { ...cameraIDs };
      project.cameras.items.map((val) => {
        return (newCameraIDs[val.id] = [
          newCameraIDs[val.id][0] ? newCameraIDs[val.id][0] : "start_date",
          currentDateTime,
        ]);
      });
      API.graphql(
        graphqlOperation(updateProject, {
          input: {
            id: props?.match?.params?.id,
            endedAt: currentDateTime,
            cameraIDs: JSON.stringify(newCameraIDs),
          },
        })
      )
        .then(() => {
          project.cameras.items.forEach((val, index) => {
            API.graphql(
              graphqlOperation(updateCamera, {
                input: {
                  id: val.id,
                  cameraProjectId: null,
                  status: "inactive",
                },
              })
            )
              .then(() => {
                if (project?.cameras?.items.length === index + 1) {
                  onHide();
                  setLoader(false);
                  setEndDelete("Delete");
                }
                window.location.reload();
              })
              .catch(({ errors }) => {
                setLoader(false);
                setDeleteError(errors[0].message);
              });
          });
        })
        .catch(({ errors }) => {
          setLoader(false);
          setDeleteError(errors && errors[0].message);
        });
    } else {
      onHide();
      setLoader(false);
      setEndDelete("Delete");
    }
  };

  const indexOfLastItem = (currentPage + 1) * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const sortedCameras = project?.cameras?.items.sort(
    (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
  );
  const currentItem = sortedCameras?.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = () => {
    API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: userID,
          notificationsEnabled: !checked,
        },
      })
    ).then(() => setChecked(!checked));
  };

  const onClickFunc = (e) => {
    setPopupCam(e);
  };
  const {
    match: { params },
  } = props;

  if (project?.pendingDeletion) {
    setInterval(function () {
      window.location.reload();
    }, 120000);
  }

  useEffect(() => {
    if (project) {
      const cams = currentItem?.filter(
        (item) => pinnedCamsIDs.indexOf(item.id) !== -1
      );
      const sortedCams = cams.sort((a, b) => {
        const indexA = pinnedCamsIDs.findIndex((id) => a.id === id);
        const indexB = pinnedCamsIDs.findIndex((id) => b.id === id);
        return indexA - indexB;
      });
      setPinnedCams(sortedCams);
      const currentCams = currentItem?.filter(
        (item) => pinnedCamsIDs.indexOf(item.id) === -1
      );
      setUnpinnedCams(currentCams);
    }
  }, [pinnedCamsIDs, project]);

  useEffect(() => {
    setTheme(window.localStorage.getItem("theme"));
  }, [window.localStorage.getItem("theme")]);

  useEffect(() => {
    if (isPinningCams) {
      API.graphql(
        graphqlOperation(updateUserProject, {
          input: {
            id: userProjectID,
            pinnedCams: pinnedCamsIDs,
          },
        })
      );
    }
  }, [isPinningCams, pinnedCamsIDs]);

  useEffect(() => {
    if (project && project.images.length) {
      Storage.get(project.images[0].s3path).then((res) => {
        setImageURI(res);
      });
    }
  }, [project]);

  useEffect(() => {
    if (project && project.images.length) {
      const fetchImages = async () => {
        const imageURIs = await Promise.all(
          project.images.map((el) => Storage.get(el.s3path))
        );
        setImagesURI(imageURIs);
      };
      fetchImages();
    }
  }, [project]);

  return (
    <div className="single-project">
      {hasID ? (
        <div style={{ fontWeight: "bolder", fontSize: 30, display: "flex" }}>
          <h1 style={{ width: "100%", textAlign: "center", marginTop: "40vh" }}>
            {translate("project-is-deleted")}
          </h1>
        </div>
      ) : (
        <>
          <div className="single-project__cover">
            <div className="single-project__cover-left">
              <div
                className="single-project__return"
                onClick={() =>
                  props.history.push({
                    pathname: "/projects",
                    state: props.location.state,
                  })
                }
              >
                &#x2190; &nbsp; {translate("all-projects")}
              </div>
              <h1>{project?.name}</h1>
              <div className="single-project__location">
                {project?.location}
              </div>
              <div className="single-project__company">
                {project?.company?.name}
              </div>
            </div>
            <div className="single-project__cover-right">
              <ButtonPrimary
                title={
                  <>
                    <FaRedoAlt size={12} />
                    &nbsp; {translate("reload")}
                  </>
                }
                onClick={() => window.location.reload()}
              />

              {!(Role === "admin" || Role === "crew") ? (
                <div className="single-project__cover-right-notification-desktop">
                  <label htmlFor="icon-switch">
                    {translate("notification")}:{" "}
                  </label>
                  <div className="single-project__cover-right-notification-switch">
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      height={30}
                      width={61}
                      onColor="#1181F2"
                      handleDiameter={35}
                      uncheckedIcon={
                        <div className="switch-unchecked-icon">
                          {translate("off")}
                        </div>
                      }
                      checkedIcon={
                        <div className="switch-checked-icon">
                          {translate("on")}
                        </div>
                      }
                      id="icon-switch"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="single-project__edit">
            {project?.pendingDeletion ? null : (
              <MediaQuery minDeviceWidth={551}>
                <div className="single-project__edit-left">
                  <button
                    className="single-project__filter-btn"
                    style={
                      listView
                        ? { backgroundColor: "#ffff" }
                        : { backgroundColor: "#1181F2" }
                    }
                    onClick={() => setListview(false)}
                  >
                    <AiFillAppstore
                      color={listView ? "#1181F2" : "#ffff"}
                      size={16}
                    />
                  </button>
                  <button
                    className="single-project__filter-btn"
                    style={
                      listView
                        ? { backgroundColor: "#1181F2" }
                        : { backgroundColor: "#ffff" }
                    }
                    onClick={() => setListview(true)}
                  >
                    <FaBars color={listView ? "#ffff" : "#1181F2"} size={16} />
                  </button>
                </div>
              </MediaQuery>
            )}
            {!(Role === "admin" || Role === "crew") ? (
              <div className="single-project__cover-right-notification-mobile">
                <label htmlFor="icon-switch">
                  {translate("notification")}:
                </label>
                <div className="single-project__cover-right-notification-switch">
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    onHandleColor="#1181F2"
                    offHandleColor="#6c757d"
                    height={30}
                    width={61}
                    handleDiameter={35}
                    uncheckedIcon={
                      <div className="switch-unchecked-icon">
                        {translate("off")}
                      </div>
                    }
                    checkedIcon={
                      <div className="switch-checked-icon">
                        {translate("on")}
                      </div>
                    }
                    id="icon-switch"
                  />
                </div>
              </div>
            ) : project?.pendingDeletion === true ? null : (
              <div className="single-project__edit-right">
                <ButtonPrimary
                  title={translate("edit-project")}
                  onClick={() =>
                    openModal({
                      id: project.id,
                      show: true,
                      editProject: true,
                    })
                  }
                />
                <ButtonPrimary
                  title={
                    endDelete === "END" ? translate("end") : translate("delete")
                  }
                  onClick={
                    endDelete === "END"
                      ? () => {
                          openDeleteProject({
                            name: project.name,
                            show: true,
                            userId: project.id,
                          });
                        }
                      : () => {
                          openDeleteProject({
                            name: project.name,
                            show: true,
                            userId: project.id,
                          });
                        }
                  }
                />
                {endDelete !== "END" && (
                  <ButtonPrimary
                    onClick={() =>
                      props.history.push(
                        `/projects/${params?.id}/archive/noCamera`
                      )
                    }
                    title={translate("archive")}
                  />
                )}
              </div>
            )}
            {Role === "clientall" || Role === "superuser" ? (
              <div className="single-project__edit-right">
                {endDelete !== "END" && (
                  <ButtonPrimary
                    onClick={() =>
                      props.history.push(
                        `/projects/${params?.id}/archive/noCamera`
                      )
                    }
                    title={translate("archive")}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <Row
            className="thumbnails-row"
            style={
              Role === "admin" || Role === "crew"
                ? { paddingTop: "64px" }
                : { paddingTop: "0px" }
            }
          >
            {dataLoader ? (
              <Loader />
            ) : project?.cameras?.items?.length ? (
              <>
                {pinnedCams?.map((camera, i) => {
                  return (
                    <SingleProjectCard
                      camera={camera}
                      key={i}
                      listView={listView}
                      Role={Role}
                      setCameraPopUp={(boolean) => setCameraPopUp(boolean)}
                      setCameraId={() => setCameraId(camera.id)}
                      onClickFunc={() => onClickFunc(camera)}
                      setLivePopUp={(boolean) => setLivePopUp(boolean)}
                      setLiveState={(setLiveData) => setLiveState(setLiveData)}
                      setTimelapsePopup={(boolean) => setOpenTimelapse(boolean)}
                      history={props.history}
                      params={params}
                      setPinnedCamFunc={(id) => {
                        setIsPinningCams(true);
                        if (pinnedCamsIDs.includes(id)) {
                          setPinnedCamesIDs(
                            pinnedCamsIDs.filter((item) => item !== id)
                          );
                        } else {
                          setPinnedCamesIDs([...pinnedCamsIDs, id]);
                        }
                      }}
                      pinnedCams={pinnedCamsIDs}
                    />
                  );
                })}
                {unPinnedCams?.map((camera, i) => {
                  return (
                    <SingleProjectCard
                      camera={camera}
                      key={i}
                      listView={listView}
                      Role={Role}
                      setCameraPopUp={(boolean) => setCameraPopUp(boolean)}
                      setCameraId={() => setCameraId(camera.id)}
                      onClickFunc={() => onClickFunc(camera)}
                      setLivePopUp={(boolean) => setLivePopUp(boolean)}
                      setLiveState={(setLiveData) => setLiveState(setLiveData)}
                      setTimelapsePopup={(boolean) => setOpenTimelapse(boolean)}
                      history={props.history}
                      params={params}
                      setPinnedCamFunc={(id) => {
                        setIsPinningCams(true);
                        if (pinnedCamsIDs.includes(id)) {
                          setPinnedCamesIDs(
                            pinnedCamsIDs.filter((item) => item !== id)
                          );
                        } else {
                          setPinnedCamesIDs([...pinnedCamsIDs, id]);
                        }
                      }}
                      pinnedCams={pinnedCamsIDs}
                    />
                  );
                })}
              </>
            ) : project?.pendingDeletion === true ? (
              <h2
                className="single-project__thumbnails-row-no-camera"
                style={{ fontWeight: "bolder", fontSize: 30 }}
              >
                {translate("pending-deletion")}{" "}
              </h2>
            ) : (
              <div
                style={{
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "auto",
                }}
              >
                <h2 className="single-project__thumbnails-row-no-camera">
                  {translate("no-camera-project")}{" "}
                </h2>

                {imageURI && (
                  <div className="responsive-image-flex">
                    {imagesURI.map((uri, i) => (
                      <div
                        className="responsive-image-flex__item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => {
                          props.history.push(
                            `/projects/${params?.id}/archive/${
                              project.images[i]?.cameraId || "noCamera"
                            }`
                          );
                        }}
                      >
                        <div className="responsive-image-flex__img">
                          <img src={uri} alt={`Project Image ${i}`} />
                        </div>
                        <button
                          className="responsive-image-flex__button"
                          onClick={() =>
                            props.history.push(
                              `/projects/${params?.id}/archive/${
                                project.images[i]?.cameraId || "noCamera"
                              }`
                            )
                          }
                        >
                          {translate("archive")} {project.images[i]?.cameraId}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </Row>

          {cameraPopUp && (
            <SingleProjectImagePopup
              open={cameraPopUp}
              onHide={() => setCameraPopUp(false)}
              id={cameraId}
              popupCam={popupCam}
            />
          )}

          <DeleteUser
            open={deleteProjectState}
            deleteFunc={
              endDelete === "END" ? endProjectFunc : deleteCompleteProjectFunc
            }
            loader={loader}
            error={deleteError}
            onHide={onHide}
            name={endDelete === "END" ? translate("end-project") : null}
            canDelete={true}
          />
          <AddEditProject
            modal={modal}
            onHide={() =>
              openModal({
                editProject: false,
                show: false,
                id: "",
              })
            }
          />

          <LiveVideoPopup
            open={livePopUp}
            onHide={() => setLivePopUp(false)}
            cameraName={liveState.cameraName}
            liveStreamLink={liveState.liveStreamLink}
          />
          <TimelapsePopup
            cameraId={liveState.cameraName}
            open={openTimelapse}
            onHide={() => setOpenTimelapse(false)}
            projectId={liveState.projectId}
          />
        </>
      )}
    </div>
  );
};

SingleProject.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default SingleProject;
