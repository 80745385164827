import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { MdContentCopy } from "react-icons/md";
import { RiDownloadCloudLine } from "react-icons/ri";
import PropTypes from "prop-types";
import "../../styles/pages/Cams/cams-popup.scss";
import { getProjectWeatherKey } from "../../helpers/manualQueries";
import translate from "../../i18n/translate";
import greyCamera from "../../assets/images/greycam.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Storage } from "aws-amplify";
import { PopupNavBtns } from "../../components/PopupNavBtns";
import { SeekBtns } from "../../components/SeekBtn";
import ActionSnackbar from "../../components/ActionSnackbar";
import PopupHeader from "../../components/PopupHeader";
import { FaRegCalendarAlt } from "react-icons/fa";
import WeatherData from "../../components/WeatherData";
import { FiExternalLink } from "react-icons/fi";

const ArchiveImagePopup = ({
  open,
  onHide,
  allCameras,
  cameraKey,
  loadMoreFunc,
  change,
  match,
}) => {
  const [Role, setRole] = useState("");
  const [imageURI, setImageURI] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState([]);
  const [weatherKey, setWeatherKey] = useState(null);

  const handleSnackbarOpen = (hasLink) => {
    if (hasLink) {
      const message = translate("successfully-copied-image-link");
      setSnackbarData([message, "info"]);
    } else {
      const message = translate("no-image-availabe");
      setSnackbarData([message, "warning"]);
    }
    setSnackbarOpen(!snackbarOpen);
  };
  useEffect(() => {
    change(imageIndex);
  }, [imageIndex]);

  const handleViewImage = () => {
    const path = cameraKey?.cameraBluredPath
      ? cameraKey.cameraBluredPath
      : cameraKey?.cameraOriginalPath;

    if (!path) {
      console.error("Image path is not available");
      return;
    }

    Storage.get(path, { download: false })
      .then((result) => {
        if (result) {
          window.open(result, "_blank"); // Opens the image in a new tab
        } else {
          console.error("Image URL is not available");
        }
      })
      .catch((err) => {
        console.error("Error fetching image:", err);
      });
  };

  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  useEffect(() => {
    getRole();
    getWeatherKey();
  }, [open]);

  const getRole = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    const role =
      userDetails?.signInUserSession?.accessToken?.payload["cognito:groups"][0];
    setRole(role.toString().toLowerCase());
  };

  const getWeatherKey = async () => {
    const project = await API.graphql(
      graphqlOperation(getProjectWeatherKey, { id: match.params.id })
    );
    const projectData = project.data.getProject;
    setWeatherKey(projectData.weather_key);
  };

  const onClose = () => {
    onHide();
  };

  useEffect(() => {
    if (cameraKey) {
      const index = allCameras.findIndex((item) => item.id === cameraKey.id);
      setImageIndex(index);
    }
  }, [cameraKey, allCameras]);

  useEffect(() => {
    if (allCameras) {
      Storage.get(allCameras[imageIndex]?.camera).then((res) => {
        setImageURI(res);
      });
    }
  }, [allCameras, imageIndex]);

  const handleDownloadImage = () => {
    const path = cameraKey?.cameraBluredPath
      ? cameraKey.cameraBluredPath
      : cameraKey?.cameraOriginalPath;
    Storage.get(path, { download: true }).then((result) => {
      let mimeType = result.ContentType;
      let fileName = path;

      try {
        let blob = new Blob([result.Body], { type: mimeType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (exc) {
        throw exc;
      }
    });
  };
  const [isImageExpanded, setIsImageExpandedTo] = useState(false);
  const [currentClass, setCurrentClassTo] = useState("camera-popup");
  const expandHandler = () => {
    if (isImageExpanded) {
      setIsImageExpandedTo(false);
    } else {
      setIsImageExpandedTo(true);
    }
  };

  useEffect(() => {
    if (isImageExpanded) {
      setCurrentClassTo("camera-popup-exp");
    } else {
      setCurrentClassTo("camera-popup");
    }
  }, [isImageExpanded]);

  const nextImageHandler = () => {
    setImageIndex(imageIndex + 1);

    if (
      imageIndex === allCameras.length - 1 ||
      imageIndex + 1 === allCameras.length - 1
    ) {
      loadMoreFunc();
    }
  };
  const previousImageHandler = () => {
    if (imageIndex != 0) {
      setImageIndex(imageIndex - 1);
    }
  };

  return (
    <Modal
      className={currentClass}
      show={open}
      onHide={() => onClose()}
      centered
    >
      <ActionSnackbar
        openBool={snackbarOpen}
        message={snackbarData[0]}
        severity={snackbarData[1]}
        onCloseSnackbar={onCloseSnackbar}
      />

      <PopupHeader Role={Role} onClose={onClose} hasCameraData={false} />
      <div className="camera-popup__head" style={{ width: "300px" }}>
        <div className="d-flex">
          <div className="camera-popup__date-time">
            <FaRegCalendarAlt size={14} color="#1181F2" />
            {cameraKey?.datetime ? (
              <p>{cameraKey?.dateTime}</p>
            ) : (
              <p>--.--.---- | --:--</p>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: 5 }}>
        <PopupNavBtns
          onClickFullscreen={expandHandler}
          onClickSeek={() => onClose()}
        ></PopupNavBtns>
      </div>
      <WeatherData
        weatherKey={weatherKey}
        imageDateTime={cameraKey?.datetime}
      />
      <SeekBtns
        nextImg={nextImageHandler}
        prevImg={previousImageHandler}
        imageURIState={imageURI}
        imgP={greyCamera}
        i={imageIndex}
        camDate={allCameras[allCameras.length - 1]?.datetime}
        dateTime={allCameras[imageIndex]?.datetime}
        camImages={allCameras}
        isArchive={false}
        inverted={true}
        cameraIDs={true}
      />

      <div className="camera-popup__opt-btns">
        <ButtonPrimary
          title={
            <>
              <FiExternalLink
                key="check"
                color="#1181f2"
                size={14}
                style={{ marginRight: "5px" }}
              />
              {translate("view-image")}
            </>
          }
          type="submit"
          onClick={handleViewImage}
        />
        {(Role === "admin" || Role === "crew" || Role === "superuser") &&
        imageURI ? (
          <CopyToClipboard text={imageURI}>
            <ButtonPrimary
              title={
                <>
                  <MdContentCopy key="copy" color="#1181f2" size={14} />
                  {translate("copy-picture-link")}
                </>
              }
              type="submit"
              onClick={() => {
                handleSnackbarOpen(imageURI ? true : false);
              }}
            />
          </CopyToClipboard>
        ) : null}

        <ButtonPrimary
          title={
            <>
              <RiDownloadCloudLine key="check" color="#1181f2" size={14} />
              {translate("download")}
            </>
          }
          type="submit"
          onClick={handleDownloadImage}
        />
      </div>
    </Modal>
  );
};

ArchiveImagePopup.propTypes = {
  open: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.string,
  cameraKey: PropTypes.object,
  change: PropTypes.func,
  allCameras: PropTypes.array,
  loadMoreFunc: PropTypes.func,
};
export default ArchiveImagePopup;
